import(/* webpackMode: "eager", webpackExports: ["Sandpack"] */ "/vercel/path0/node_modules/@codesandbox/sandpack-react/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["DocsContext"] */ "/vercel/path0/src/app/[...slug]/DocsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/vercel/path0/src/app/[...slug]/Menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/mdx/Code/Code.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/mdx/Toc/Toc.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Nav/NavCategoryCollapsible.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Search/index.tsx");
